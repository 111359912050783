var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$apollo.loading && !_vm.form._id)?_c('Loading'):[_c('v-tabs-items',{attrs:{"id":"form-edit__tabs-items"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{staticClass:"ml-2",attrs:{"value":"component"}},[_c('Fields',{attrs:{"id":"form-edit__tab-component-1","schema":{
            name: {
              type: 'string',
              label: 'Nombre',
              description: 'Debe ser único y reconocible.',
              sizeSmall: 12,
              sizeLarge: 6,
              newDesign: true
            },
            title: {
              type: 'string',
              label: 'Título',
              description: 'Opcional. Se mostrará en las vistas.',
              sizeSmall: 12,
              sizeLarge: 6,
              optional: true,
              newDesign: true
            },
            roles: {
              type: 'roleSelect',
              __graphQLType: '[ID]',
              label: 'Roles',
              fieldOptions: {
                multi: true
              },
              optional: true,
              newDesign: true
            },
            collectionId: {
              type: 'collectionSelect',
              label: 'Colección',
              fieldOptions: {
                disabled: true,
                readonly: true
              },
              newDesign: true
            },
            type: {
              type: 'string',
              fieldType: 'select',
              label: 'Tipo',
              fieldOptions: {
                options: [
                  { label: 'Crear registro', value: 'create' },
                  { label: 'Actualizar registro existente', value: 'update' },
                  { label: 'Híbrido (actualizar registro, crear si no existe)', value: 'upsert' }
                ]
              },
              newDesign: true
            }
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('v-slide-y-transition',[(_vm.form.type === 'update' || _vm.form.type === 'upsert')?_c('Fields',{attrs:{"id":"form-edit__tab-component-2","schema":Object.assign({}, {updateVariableName: {
                type: 'string',
                label: 'Nombre de la variable',
                description: 'Se extrae de la vista y se usa para buscar el valor a editar (ID)',
                optional: true,
                newDesign: true
              }},
              (_vm.form.type === 'update' ? {
                  hideEmpty: {
                  type: 'checkbox',
                  label: 'Esconder el formulario si no existe la variable',
                  optional: true,
                  newDesign: true
                },
                emptyFormText: {
                  type: 'string',
                  label: 'Texto a mostrar en un formulario vacio',
                  optional: true,
                  newDesign: true
                },
              } : {}),
              {updateFilterId: {
                type: 'string',
                fieldType: 'filterSelect',
                label: 'Filtro a usar',
                description: 'Se usará el primer resultado de este filtro como item a editar',
                optional: true,
                newDesign: true
              },
              updateFilterParamNames: {
                type: 'string',
                label: 'Campos del formulario a pasar como parámetros al filtro',
                description: 'Separados por comas (Ej: nombre, apellido). Estos campos se usarán solo para filtrar y no se actualizarán en el item.',
                optional: true,
                newDesign: true
              }}),"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId, collectionId: _vm.form.collectionId },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e()],1)],1),_c('v-tab-item',{staticStyle:{"height":"100%"},attrs:{"value":"fields"}},[_c('FormFields',{attrs:{"name":"fields","errorMessages":"","disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId, collectionId: _vm.form.collectionId, formId: _vm.form._id },"validationErrors":{}},model:{value:(_vm.form.fields),callback:function ($$v) {_vm.$set(_vm.form, "fields", $$v)},expression:"form.fields"}})],1),_c('v-tab-item',{staticClass:"ml-2",attrs:{"value":"appearance"}},[_c('v-card-text',{staticClass:"py-2"},[_c('h1',{staticClass:"headline font-weight-bold"},[_vm._v("Botones del formulario")]),_c('v-card',{staticClass:"mt-2 pa-2",attrs:{"color":"#f4f4f4"}},[_c('v-card-actions',[(_vm.form.buttonAlignment === 'right' || _vm.form.buttonAlignment === 'center')?_c('v-spacer'):_vm._e(),(_vm.form.reset)?_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("undo")]),_vm._v(" "+_vm._s(_vm.form.resetButtonText || 'Revertir')+" ")],1):_vm._e(),_c('v-btn',{attrs:{"color":_vm.form.submitButtonBgColor || 'primary',"dark":!!_vm.form.submitButtonBgColor,"type":"submit"}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.normalizedIcon)}}),_vm._v(" "+_vm._s(_vm.normalizedButtonText)+" ")],1),(_vm.form.buttonAlignment === 'center')?_c('v-spacer'):_vm._e()],1)],1)],1),_c('Fields',{attrs:{"id":"form-edit__tab-appearance-1","schema":{
              buttonAlignment: {
                type: 'select',
                label: 'Alinear Botones',
                fieldOptions: {
                  options: [
                    { label: 'Izquierda', value: 'left' },
                    { label: 'Centro', value: 'center' },
                    { label: 'Derecha', value: 'right' }
                  ]
                },
                optional: true,
                newDesign: true
              },
              submitButtonBgColor: {
                type: 'color',
                label: 'Color del botón principal',
                optional: true,
                sizeSmall: 12,
                sizeLarge: 4,
                newDesign: true
              },
              submitButtonText: {
                type: 'string',
                label: 'Texto del botón principal',
                optional: true,
                fieldOptions: {
                  placeholder: _vm.normalizedButtonText
                },
                sizeSmall: 12,
                sizeLarge: 4,
                newDesign: true
              },
              submitButtonIcon: {
                type: 'string',
                label: 'Ícono del botón principal',
                description: 'Visitar https://material.io/icons para una lista de íconos.',
                sizeSmall: 12,
                sizeLarge: 4,
                optional: true,
                fieldOptions: {
                  placeholder: _vm.normalizedIcon
                },
                newDesign: true
              },
              reset: {
                type: 'checkbox',
                label: 'Mostrar botón Revertir',
                optional: true,
                newDesign: true
              },
            },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId, collectionId: _vm.form.collectionId },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}),_c('v-slide-y-transition',[(_vm.form.reset)?_c('v-card-text',{staticClass:"py-0 mt-1",staticStyle:{"margin-top":"-16px"}},[_c('label',{staticStyle:{"color":"#8a8a8a","font-size":"14px"}},[_vm._v("Texto de botón Revertir")]),(false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("help ")])]}}],null,false,3383618807)},[_c('span',[_vm._v("Nombre de la tabla")])]):_vm._e(),_c('div',{staticClass:"new-field-text-revert"},[_c('v-text-field',{staticClass:"mt-1",attrs:{"outlined":"","dense":""},model:{value:(_vm.form.resetButtonText),callback:function ($$v) {_vm.$set(_vm.form, "resetButtonText", $$v)},expression:"form.resetButtonText"}})],1)],1):_vm._e()],1)],1),_c('v-tab-item',{staticClass:"ml-2",attrs:{"value":"actions"}},[_c('Fields',{attrs:{"schema":{
            _authSection: {
              type: 'section',
              editableLabel: 'Seguridad'
            },
            requireTwoFactor: {
              type: 'checkbox',
              label: 'Requerir autenticación de 2 factores',
              optional: true,
              newDesign: true
            },
            _validationsSection: {
              type: 'section',
              editableLabel: 'Validaciones'
            },
            validationsIds: {
              type: 'validationSelect',
              label: 'Validaciones',
              fieldOptions: {
                multi: true
              },
              optional: true,
              newDesign: true
            },
            validationsMode: {
              type: 'select',
              label: 'Modo de validaciones',
              fieldOptions: {
                options: [
                  { value: 'AND', label: 'Todas deben cumplirse (AND)' },
                  { value: 'OR', label: 'Al menos una debe cumplirse (OR)' }
                ]
              },
              optional: true,
              newDesign: true
            },
            _logicSection: {
              type: 'section',
              editableLabel: 'Lógica'
            },
            afterHooksIds: {
              type: 'hookSelect',
              label: 'Hooks a ejecutar',
              fieldOptions: {
                multi: true
              }
            },
            shouldStopHooksOnError: {
              type: 'checkbox',
              label: 'Detener ejecución de hooks si ocurre un error',
              optional: true,
              newDesign: true
            },
            shouldDeleteItemOnError: {
              type: 'checkbox',
              label: 'Eliminar item si se interrumpe la ejecución de hooks tras error',
              optional: true,
              newDesign: true
            },
            _onFinishSection: {
              type: 'section',
              editableLabel: 'Al finalizar'
            },
            onSuccessViewPath: {
              type: 'string',
              label: 'Ir a una ruta al terminar',
              optional: true,
              newDesign: true
            },
            onSuccessNewTab: {
              type: 'checkbox',
              label: 'Abrir en una pestaña nueva',
              optional: true,
              newDesign: true
            },
            onSuccessEnvironmentVariables: {
              type: 'collectionFieldSelect',
              label: 'Definir parámetros de vista con resultado de formulario',
              fieldOptions: {
                multi: true
              },
              optional: true,
              newDesign: true
            },
            postItemToUrl: {
              type: 'string',
              label: 'Enviar resultado a esta URL al finalizar',
              description: 'Se realizará una solicitud POST con los campos en formato JSON',
              optional: true,
              newDesign: true
            }
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId, collectionId: _vm.form.collectionId },"validationErrors":{}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Formulario"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }