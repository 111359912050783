





















































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { FormEditFragment, FormCreateFragment } from './fragments'
import { Form } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _snakeCase from 'lodash/snakeCase'
import _isEqual from 'lodash/isEqual'
import Fields from '@/components/form/Fields.vue'
import FormFields from '@/components/fields/formFields/Field.vue'
import Loading from '@/components/Loading.vue'
import cleanData from '@/utils/gql/cleanData'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields,
    FormFields
  },
  apollo: {
    savedForm: {
      query: gql`query getForm ($formId : ID) {
        savedForm: form (formId: $formId) {
          ...FormCreate
          ...FormEdit
        }
      }
      ${FormCreateFragment}
      ${FormEditFragment}`,
      variables () {
        return {
          formId: this.componentId
        }
      }
    }
  }
})
export default class FormEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: String
  @Prop({ type: String, required: true }) componentId !: String
  @Prop({ type: String, default: 'component' }) currentTab !: String

  savedForm : Form | null = null
  form : Partial<Form> = {}

  saving = false
  valid = false

  @Watch('savedForm')
  update (newData : Form) {
    this.$set(this, 'form', _cloneDeep(this.savedForm))
  }

  @Watch('form.name')
  updateName (newName : string) {
    this.$emit('name', newName)
  }

  get normalizedIcon () {
    if (!this.form) return ''
    let icon = this.form.submitButtonIcon
    if (!icon) {
      icon = this.form.type === 'create' ? 'add' : 'save'
    }
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  get normalizedButtonText () {
    if (!this.form) return ''
    if (this.form.submitButtonText) return this.form.submitButtonText
    return this.form.type === 'create' ? 'Crear' : 'Guardar'
  }

  mounted () {
    this.$emit('additionalTabs', {
      fields: 'Campos',
      appearance: 'Apariencia',
      actions: 'Acciones'
    })
  }

  async save () {
    if (!this.form || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($formId : ID, $form: UpdateFormInput) {
          updateForm (formId: $formId, form: $form) {
            ...FormCreate
            ...FormEdit
          }
        }
        ${FormCreateFragment}
        ${FormEditFragment}`,
        // Parameters
        variables: {
          formId: this.form._id,
          form: cleanData(this.form, FormEditFragment)
        }
      })

      this.savedForm = result.data.updateForm
      this.$emit('save', result.data.updateForm)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este formulario del ambiente?')) return
    if (!this.form || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($formId : ID) {
          removeForm (formId: $formId)
        }`,
        // Parameters
        variables: {
          formId: this.form._id
        }
      })
      this.$emit('delete', result.data.removeForm)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  get dirty () {
    return !_isEqual(this.form, this.savedForm)
  }

  dismiss () {
    this.$emit('dismiss')
  }
}
